import appAjax from './appAjax'
export default class KitchenOverview {
  constructor() {
    this.url = {
      filter: '/keukens/filter',
    }
    ;(this.filterListener = 'kitchenFilterEvent'),
      (this.elements = {
        loading: '.overview-loading',
        results: '.kitchen-wrapper',
      })
    this.kitchenLocation = new KitchenLocation()
    this.kitchenFilters = new KitchenFilters(this.kitchenLocation, this.filterListener)
    this.setEventListeners()
    this.search() // first init load kitchens
  }

  setEventListeners() {
    window.addEventListener(
      this.filterListener,
      function () {
        this.search()
      }.bind(this)
    )

    window.onpopstate = function () {
      this.kitchenFilters.stateLoad()
      this.search()
    }.bind(this)
  }

  search() {
    var filters = this.kitchenFilters.getFilters()

    this.showModal()

    new appAjax({
      url: this.url.filter,
      method: 'POST',
      data: filters,
      success: function (response) {
        this.handleResponse(response)
      }.bind(this),
    })
  }

  handleResponse(response) {
    document.querySelector(this.elements.results).innerHTML = response
    this.closeModal()
  }

  showModal() {
    document.querySelector(this.elements.loading).style.display = 'block'
  }

  closeModal() {
    document.querySelector(this.elements.loading).style.display = 'none'
  }
}

class KitchenFilters {
  constructor(kitchenLocation, filterListener) {
    this.kitchenLocation = kitchenLocation
    this.filterListener = filterListener
    this.filterInput = '[name^=filter]'
    this.filters = []
    this.init()
  }
  init() {
    this.setEvents()
    this.parseLocation()
  }
  stateLoad() {
    this.clearFilters()
    this.parseLocation()
  }
  parseLocation() {
    var filters = this.kitchenLocation.parse()
    if (filters !== false) {
      for (var name in filters) {
        // Skip Google Analytics and other tracking parameters
        if (filters.hasOwnProperty(name) && !name.startsWith('_')) {
          var filterValues = decodeURIComponent(filters[name])
          filterValues = filterValues.split(',')
          for (var value in filterValues) {
            if (filterValues.hasOwnProperty(value)) {
              var element = document.querySelector('#' + name + '-' + filterValues[value])
              if (element !== null) {
                element.checked = true
                this.handleInput(element)
              }
            }
          }
        }
      }
    }
  }

  clearFilters() {
    for (var name in this.filters) {
      if (this.filters.hasOwnProperty(name)) {
        var filterValues = this.filters[name]
        for (var value in filterValues) {
          if (filterValues.hasOwnProperty(value)) {
            var element = document.querySelector('#' + name + '-' + filterValues[value])
            if (element !== null) {
              element.checked = false
            }
          }
        }
      }
    }

    // Clear filters because parseLocation() will be executed again
    this.filters = []
  }

  setEvents() {
    var filters = document.querySelectorAll(this.filterInput)

    for (var count = 0; count < filters.length; count++) {
      filters[count].addEventListener(
        'change',
        function (event) {
          var input = event.target
          this.handleInput(input)

          var customEvent = new CustomEvent(this.filterListener, { bubbles: true })
          window.dispatchEvent(customEvent)

          this.kitchenLocation.update(this.filters)
        }.bind(this)
      )
    }
  }

  handleInput(input) {
    var inputType = input.type

    if (input.classList.contains('checkbox--single')) {
      inputType = 'radio'
      var inputGroup = input.getAttribute('data-group'),
        singleInput = document.querySelectorAll('[data-group="' + inputGroup + '"]')

      for (var subCount = 0; subCount < singleInput.length; subCount++) {
        if (input !== singleInput[subCount]) {
          singleInput[subCount].checked = false
        }
      }
    }

    if (inputType === 'text') {
      this.handleFilterText(input, inputType)
    } else if (inputType === 'checkbox') {
      this.handleFilterCheckbox(input, inputType)
    } else if (inputType === 'radio') {
      this.handleFilterRadio(input, inputType)
    }
  }

  handleFilterText(input, inputType) {
    if (input.value === '999999999') {
      this.removeFilter(input, inputType)
    } else {
      this.addFilter(input, inputType)
    }
  }

  handleFilterCheckbox(input, inputType) {
    if (input.checked) {
      this.addFilter(input, inputType)
    } else {
      this.removeFilter(input, inputType)
    }
  }

  handleFilterRadio(input, inputType) {
    // For a radio button, always remove the last set filter, when removed add filter again with new value
    this.removeFilter(input, inputType)
    if (input.checked) {
      this.addFilter(input, inputType)
    }
  }

  addFilter(input, inputType) {
    var filterName = this.parseFilterName(input.name)

    if (inputType === 'checkbox') {
      this.addFilterCheckbox(filterName, input.value)
    } else {
      this.addFilterByName(filterName, input.value)
    }
  }

  addFilterCheckbox(filterName, value) {
    if (!this.filters.hasOwnProperty(filterName)) {
      // create empty array
      this.filters[filterName] = []
    }

    this.filters[filterName].push(value)
  }

  addFilterByName(filterName, value) {
    this.filters[filterName] = value
  }

  removeFilter(input, inputType) {
    var filterName = this.parseFilterName(input.name)
    if (inputType === 'checkbox') {
      this.removeFilterCheckbox(filterName, input.value)
    } else {
      this.removeFilterByName(filterName)
    }
  }

  removeFilterCheckbox(filterName, value) {
    if (this.filters.hasOwnProperty(filterName)) {
      // Remove from array
      var index = this.filters[filterName].indexOf(value)
      if (index !== -1) {
        this.filters[filterName].splice(index, 1)
      }

      // remove filter from array when empty
      if (this.filters[filterName].length === 0) {
        delete this.filters[filterName]
      }
    }
  }

  removeFilterByName(filterName) {
    delete this.filters[filterName]
  }

  parseFilterName(name) {
    return name.replace(/filter\[(.*)\]/i, '$1')
  }

  getFilters() {
    return this.filters
  }
}

class KitchenLocation {
  constructor() {
    this.filters = {}
  }

  parse() {
    var searchParams = new URLSearchParams(window.location.search)
    var filters = {}

    for (let [key, value] of searchParams.entries()) {
      // Skip Google Analytics and other tracking parameters
      if (!key.startsWith('_')) {
        filters[key] = value
      }
    }

    return Object.keys(filters).length > 0 ? filters : false
  }

  update(filters) {
    this.filters = filters
    this.setSearchParams()
  }

  setSearchParams() {
    var newPath = window.location.protocol + '//' + window.location.host + window.location.pathname,
      queryString = this.createQueryString()

    if (queryString !== '') {
      newPath += '?' + queryString
    }

    history.pushState(null, '', newPath)
  }

  createQueryString() {
    var parts = []
    for (var part in this.filters) {
      if (this.filters.hasOwnProperty(part)) {
        parts.push(encodeURIComponent(part) + '=' + encodeURIComponent(this.filters[part]))
      }
    }
    return parts.join('&')
  }
}
